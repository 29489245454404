import React, { useContext } from 'react';
import './CSS/ShopCategory.css';
import { ShopContext } from '../Context/ShopContext';
import dropdown_icon from '../Component/Assets/dropdown_icon.png';
import Item from '../Component/Item/Item';

const ShopCategory = (props) => {
  const {all_product} = useContext(ShopContext)
  return (
    <div className='shop-category'>
      <img src={props.banner} alt="" srcset="" />
      <div className="shopcategory-indexSort">
        <p>
          <span>Showing 1-12</span> out of 36 Products
        </p>
        <div className="shopcategory-sort">
        Sort by <img src={dropdown_icon} alt="" srcset="" />
        </div>
      </div>
      <div className="shopcategory-products">
        <h2>{props.category}</h2>
      {all_product.map((item, i) => {
    if (props.category === item.category) {
      return <Item key={i} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />;
    } else {
      // return <h2>Data Not Found</h2>;
    }
  })}
      </div>
    </div>
  )
}

export default ShopCategory
