import React from 'react'

const Product = () => {
  return (
    <div>
      <h2>Product</h2>
    </div>
  )
}

export default Product
