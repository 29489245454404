import React from 'react'

const LoginSignup = () => {
  return (
    <div>
      <h2>This is Login SignUp </h2>
    </div>
  )
}

export default LoginSignup
