import React from 'react';
import './Footer.css';
import footer_logo from '../Assets/logo_big.png';
import instagram_icosn from '../Assets/instagram_icon.png';
import pinters_icons from '../Assets/pintester_icon.png';
import whatsapp_icons from '../Assets/whatsapp_icon.png';


const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-logo">
        <img src={footer_logo} alt="" />
        <p>Shopping</p>
        </div>
        <ul className="footer-links">
            <li>Company</li>
            <li>Products</li>
            <li>Offices</li>
            <li>About</li>
            <li>Contact</li>
        </ul>
        <div className="footer-social-icons">
            <div className="footer-icons-container">
                <img src={instagram_icosn} alt="" srcset="" />
            </div>
            <div className="footer-icons-container">
                <img src={pinters_icons} alt="" srcset="" />
            </div>
            <div className="footer-icons-container">
                <img src={whatsapp_icons} alt="" srcset="" />
            </div>
        </div>
        <div className="footer-copyright">
            <hr/>
            <p>Copyright @ 2024 - All Right  Reserved</p>
        </div>
      
    </div>
  )
}

export default Footer
