import React from 'react';
import './NewsLetter.css';

const NewsLetter = () => {
  return (
    <div className='newsletter'>
       <h1>Get Exclusive Offers On Your E-mail</h1>
       <p>Subscribe to our newsletter and stay updated</p>
       <div>
          <input type="email" name='email' placeholder='Enter Your E-mail Id' />
          <button>Subscribe</button>
       </div>
    </div>
  )
}

export default NewsLetter
