import React from 'react';
import './Newcollections.css';
import new_collection from '../Assets/new_collections';
import Item from '../Item/Item';

const Newcollections = () => {
  return (
    <div className='new-collections'>
        <h1>New Collections</h1>
        <hr/>
        <div className="collection">
          {new_collection.map((item,i)=>{
             return <Item key={i} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/>

          })}
        </div>
      
    </div>
  )
}

export default Newcollections
