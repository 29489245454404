import React from 'react'
import Hero from '../Component/Hero/Hero'
import Popular from '../Component/Popular/Popular'
import Offers from '../Component/Offers/Offers'
import Newcollections from '../Component/NewCollections/Newcollections'
import NewsLetter from '../Component/NewsLetter/NewsLetter'

const Shop = () => {
  return (
   <>
    <Hero/>
    <Popular/>
    <Offers/>
    <Newcollections/>
    <NewsLetter/>
   </>
  )
}

export default Shop
