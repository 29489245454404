import React from 'react'

const Cart = () => {
  return (
    <div>
      <h2>This cart Page</h2>
    </div>
  )
}

export default Cart
